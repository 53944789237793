import { Component } from '@angular/core';
import { StorageService } from '@app/services/storage.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  tgBotUrl = environment.tg_bot_url;

  constructor(
    private storage: StorageService
  ) {}

  isLoggedIn() {
    return this.storage.isLoggedIn() && this.storage.getLoaded();
  }
}
