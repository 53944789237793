export const environment = {
  production: false,
  api_endpoint: 'https://lk-semashko.emsoft.ru',
  // api_endpoint: 'https://lk.semashko.com',
  // api_endpoint: 'http://192.168.88.17:7263',
  // api_endpoint: 'http://192.168.88.45:5078',
  // api_endpoint: 'https://192.168.88.45:7078',
  // api_endpoint: 'http://192.168.88.140:7263', //
  tg_bot_url: 'https://t.me/rzd_semashko_test_bot'
};
